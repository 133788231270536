import React from 'react'
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Segment,
  Icon
} from 'semantic-ui-react'
import { Link } from 'gatsby'
import FluidImageNotebook from './images/FluidImageNotebook'

const Home = () => (<div>
  <Segment style={{
      padding: '8em 0em'
    }} vertical="vertical">
    <Grid container="container" stackable="stackable" verticalAlign='middle'>
      <Grid.Row>
        <Grid.Column width={8}>
          <Header as='h3' style={{
              fontSize: '2em'
            }}>
            Transformando idéias em produtos!
          </Header>
          <p style={{
              fontSize: '1.33em'
            }}>
            Transformamos aquela idéia inovadora em realizade. Um novo App, um modulo que fará diferença competitiva, uma nova integração de dados, uma arquitetura de referencia, seja qual for sua necessidade, se tecnologia é parte da solução conte com a gente!
          </p>
          <Header as='h3' style={{
              fontSize: '2em'
            }}>
            Tecnologias de ponta
          </Header>
          <p style={{
              fontSize: '1.33em'
            }}>
            Trabalhamos as com tecnologias mais recentes aproveitando ao máximo os recursos avançados disponiveis atualmente.
          </p>
        </Grid.Column>
        <Grid.Column floated='right' width={6}>
          <FluidImageNotebook />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column textAlign='center'>
          <Link to='/produtos'>
            <Button size='huge'>Conheça algumas possibilidades</Button>
          </Link>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>

  <Segment style={{
      padding: '0em'
    }} vertical="vertical" color='black'>
    <Grid columns='equal' stackable="stackable">
      <Grid.Row textAlign='center'>
        <Grid.Column style={{
            paddingBottom: '5em',
            paddingTop: '5em'
          }}>
          <Header as='h3' style={{
              fontSize: '2em'
            }}>
            "Qualquer produto que precise de um manual para funcionar está destruído"
          </Header>
          <p style={{
              fontSize: '1.33em'
            }}>Elon Musk</p>
        </Grid.Column>
        <Grid.Column style={{
            paddingBottom: '5em',
            paddingTop: '5em'
          }}>
          <Header as='h3' style={{
              fontSize: '2em'
            }}>
            "A única maneira de se definir o limite do possível é ir além dele, para o impossível."
          </Header>
          <p style={{
              fontSize: '1.33em'
            }}>
            Arthur C. Clarke
          </p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>

  <Segment style={{
      padding: '8em 0em'
    }} vertical="vertical" color='yellow'>
    <Container text="text">
      <Header as='h3' style={{
          fontSize: '2em'
        }}>
        Terceirize problemas complicados
      </Header>
      <p style={{
          fontSize: '1.33em'
        }}>
        Esta prevendo problemas no futuro próximo, ou esta enfrentando dificuldades em seus atuais projetos? Conta com a gente pra encontrar uma solução definitiva!
      </p>
      <Link to='/servicos#terceirize'>
        <Button as='a' size='large'>
          Preciso de ajuda
        </Button>
      </Link>

      <Divider as='h4' className='header' horizontal="horizontal" style={{
          margin: '3em 0em',
          textTransform: 'uppercase'
        }}>
         <Icon className='cube'/>
      </Divider>

      <Header as='h3' style={{
          fontSize: '2em'
        }}>
        Consultoria
      </Header>
      <p style={{
          fontSize: '1.33em'
        }}>
        Realizamos consultorias em diversos assuntos ligados a tecnologia da informação
      </p>
      <Link to='/servicos#consultoria'>
        <Button as='a' size='large'>
          Quero saber mais
        </Button>
      </Link>
    </Container>
  </Segment>
</div>)

export default Home
