import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import HomepageLayout from '../components/HomepageLayout'
import Home from '../components/Home'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" lang="pt" keywords={['software', 'tecnology', 'web', 'b2b', 'e-comerce']}  />
    <HomepageLayout page='home' >
      <Home />
    </HomepageLayout>
  </Layout>
)

export default IndexPage
